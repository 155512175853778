import memoize from 'lodash/memoize';

import getStateValue from '../getStateValue';

/**
 * Function for get Reseller settings.
 * @param {string} resellerId
 * @return {Object}
 */
const getResellerData = memoize(async (resellerId) => {
  if (!resellerId) throw new Error('reseller not found');

  const staticHost = getStateValue('apiUrls.staticService');
  const timestamp = Date.now();
  const request = `${staticHost}/resellers-settings/${resellerId}.json?timestamp=${timestamp}`;
  const response = await fetch(request, { method: 'GET' });

  return response.json();
});

export default getResellerData;
