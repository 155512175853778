import dom from '../../../../../../wrapper/DomWrapper';

/**
 * Connect script
 * @param {string} id
 * @param {string} src
 * @returns {Promise<unknown>}
 */
const connectScript = (id, src) => new Promise((resolve, reject) => {
  const existingScript = dom.getElement(`#${id}`);

  if (existingScript) dom.removeElement(existingScript);

  const script = dom.createElement('script');

  script.id = id;
  script.src = src;
  script.defer = true;

  script.addEventListener('load', resolve);
  script.addEventListener('error', reject);

  dom.document.body.append(script);
});

export default connectScript;
