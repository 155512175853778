import getResellerData from './getResellerData';
import getResellerId from './getResellerId';

const getEcomDashUrls = async () => {
  try {
    const resellerId = getResellerId();

    return getResellerData(resellerId);
  } catch (error) {
    console.error(error);
  }
};

export default getEcomDashUrls;
