const MAX_WIDTH_SVG = 300;
const MAX_HEIGHT_SVG = 150;

export const getSVGPromise = async (path) => {
  const response = await fetch(path);
  const svg = await response.text();

  return new DOMParser()
    .parseFromString(svg, 'text/xml')
    .querySelector('svg');
};

export const getSizeSVGFromViewBox = async (nodeImage) => {
  if (nodeImage?.src && nodeImage.src.includes('.svg')) {
    const svgElement = await getSVGPromise(nodeImage.src);
    // rechecking style size for first loading (not cache)
    const isSetSizeAttr = !!(svgElement.getAttribute('width') && svgElement.getAttribute('height'));

    if (isSetSizeAttr) {
      const styleWidth = svgElement.width.baseVal.value;
      const styleHeight = svgElement.height.baseVal.value;

      return {
        width: styleWidth,
        height: styleHeight,
      };
    }

    const { width, height } = svgElement.viewBox.baseVal;
    const ratio = width / height;

    if (ratio > 1) {
      return {
        width: MAX_WIDTH_SVG,
        height: MAX_WIDTH_SVG / ratio,
      };
    }

    if (ratio < 1) {
      return {
        width: MAX_HEIGHT_SVG * ratio,
        height: MAX_HEIGHT_SVG,
      };
    }

    if (ratio === 1) {
      return {
        width: MAX_HEIGHT_SVG,
        height: MAX_HEIGHT_SVG,
      };
    }

    return { width, height };
  }

  return {};
};

export default {
  getSVGPromise,
  getSizeSVGFromViewBox,
};
